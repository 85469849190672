import React from 'react';
import styled from 'styled-components';
import { companyData, footerHeight } from '../../constants';
import FacebookLogo from '../FacebokLogo';
import LinkedInLogo from '../LinkedInLogo';


const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 15px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  height: ${footerHeight};
`;

const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterText = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

const StyledFacebookLogo = styled(FacebookLogo)`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    margin-right: 35px;
  }
`;

const StyledLinkedInLogo = styled(LinkedInLogo)`
  height: 30px;
  width: 30px;
  margin-left: 10px;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    margin-left: 35px;
  }
`;


const Footer = () => (
  <FooterContainer>
    <StyledFacebookLogo />
    <FooterTextWrapper>
      <FooterText href={`mailto:${companyData.email}`}>
        {companyData.email}
      </FooterText>
      <FooterText href={`tel:${companyData.phoneNumber}`}>
        {companyData.phoneNumber}
      </FooterText>
    </FooterTextWrapper>
    <StyledLinkedInLogo />
  </FooterContainer>
);

export default Footer;
