import React, { useState } from 'react';
import styled from 'styled-components';
import SvgBurger from '../fromSvg/Burger';
import MenuItems from './MenuItems';
import SelectLanguage from './SelectLanguage';
import { navBarHeightSmall } from '../../constants';


const burgerMenuHeight: number = 300;

const SvgWrapper = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  height: ${navBarHeightSmall};
  right: 10px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.blue};
  border: none;
  cursor: pointer;
`;

/** This padding-top has value like navbar height to
 * place the drop-down menu below the navbar */
const MenuWrapper = styled.div<{ isOpened: boolean }>`
  overflow: hidden;
  position: relative;
  padding-top: ${navBarHeightSmall};
  background-color: ${({ theme }) => theme.colors.blue};
  box-shadow: 0 4px ${({ theme, isOpened }) => (isOpened ? theme.colors.linkShadow : 'none')};
  height: ${({ isOpened }) => (isOpened ? `${burgerMenuHeight}px` : '0')};
  transition: height 400ms ease-in-out;
  
`;

const MenuList = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LangContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 99;
  top: 0;
  height: ${navBarHeightSmall};
  right: 60px;
`;

interface BurgerMenuProps {
  langsMenu: Array<{
    langKey: string,
    link: string,
    selected: boolean,
  }>,
}

const BurgerMenu = ({
  langsMenu,
}: BurgerMenuProps) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <MenuWrapper isOpened={isOpened}>
      <LangContainer>
        <SelectLanguage
          langsMenu={langsMenu}
        />
      </LangContainer>
      <SvgWrapper
        onClick={() => setIsOpened(!isOpened)}
      >
        <SvgBurger
          isOpened={isOpened}
          width={30}
          height={30}
        />
      </SvgWrapper>
      <MenuList className="fontCaviarDreams">
        <MenuItems
          isReachable={isOpened}
        />
      </MenuList>
    </MenuWrapper>
  );
};

export default BurgerMenu;
