import { defaultLanguageKey } from '../data/languages';
import { baseUrl } from '../constants';
import i18n from './i18n';


/* eslint-disable prefer-template */
export function getCanonicalUrl(page: PageType, language: LanguageCode) {
  if (page === 'home' && language === 'en') {
    return baseUrl + '/en/';
  }
  if (page === 'home' && language === 'pl') {
    return baseUrl + '/';
  }
  const languageInsertion = language === 'en' ? '/en/' : '/';
  return baseUrl + languageInsertion + page;
}
/* eslint-enable prefer-template */

export function reduceToObject<K, V>(
  array: Array<{[k: string]: string}>,
  key: string,
  values: string
) {
  // @ts-ignore
  return array.reduce<Record<K, V>>((aggregator, item) => ({
    ...aggregator,
    [item[key]]: item[values],
    // @ts-ignore
  }), {} as Record<K, V>);
}

export function getLinkAdres(to: PageType) {
  const linkPrefix = i18n.language === defaultLanguageKey ? '/' : `/${i18n.language}/`;
  return `${linkPrefix}${to}`;
}
