import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


const LogoWrapper = styled.div<{ backgroundimage: string }>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 100px;
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: 80px;
    width: 140px;
  }
`;

const LogoWombat = () => {
  const data = useStaticQuery(graphql`
      {
        file(name: { eq: "logoWombat" }) {
        publicURL
      }
    }
  `);

  return (
    <LogoWrapper backgroundimage={data.file.publicURL} />
  );
};

export default LogoWombat;
