import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import languagesConfig from '../../data/languages';


const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  border-bottom: 3px solid transparent;
  z-index: 98;
  transition: all 200ms ease-in-out;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
`;

interface MenuItemsProps {
  isReachable: boolean,
}

const MenuItems = ({ isReachable }: MenuItemsProps) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const { defaultLanguageKey } = languagesConfig;
  const linkPrefix = i18n.language === defaultLanguageKey ? '' : `/${i18n.language}`;
  const homeLink = i18n.language === defaultLanguageKey ? '/' : `/${i18n.language}/`;

  return (
    <>
      <StyledListItem>
        <StyledLink
          to={homeLink}
          activeStyle={{ borderColor: `${themeContext.colors.white}` }}
          className="menuLink"
          tabIndex={isReachable ? 0 : -1}
        >
          {t('home')}
        </StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink
          to={`${linkPrefix}/portfolio`}
          activeStyle={{ borderColor: `${themeContext.colors.white}` }}
          className="menuLink"
          tabIndex={isReachable ? 0 : -1}
        >
          {t('portfolio')}
        </StyledLink>
      </StyledListItem>
      <StyledListItem>
        <StyledLink
          to={`${linkPrefix}/contact`}
          activeStyle={{ borderColor: `${themeContext.colors.white}` }}
          className="menuLink"
          tabIndex={isReachable ? 0 : -1}
        >
          {t('contact')}
        </StyledLink>
      </StyledListItem>
    </>
  );
};

export default MenuItems;
