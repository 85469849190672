import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';


const StyledLinkedInLogo = styled.a<{backgroundimage: string}>`
  background-image: ${({ backgroundimage }) => `url(${backgroundimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-decoration: none;
  height: 40px;
  width: 40px;
  transition: transform ease-in-out 200ms;
  
  :hover {
    transform: scale(1.1, 1.1);
  }
`;

const LinkedInLogo = (props: unknown) => {
  const data = useStaticQuery(graphql`
      query {
        svg: file(name: { eq: "linkedInLogo" }) {
          publicURL
        }
      }
    `);

  return (
    <StyledLinkedInLogo
      backgroundimage={data.svg.publicURL}
      target="_blank"
      rel="noreferrer noopener"
      href="https://www.linkedin.com/company/common-wombat/"
      {...props}
    />
  );
};

export default LinkedInLogo;
