import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonPl from '../data/locales/pl/common.json';
import commonEn from '../data/locales/en/common.json';
import opinionsEn from '../data/locales/en/opinions.json';
import opinionsPl from '../data/locales/pl/opinions.json';
import languagesConfig from '../data/languages';


i18n
  .use(initReactI18next)
  .init({
    fallbackLng: languagesConfig.defaultLanguageKey,
    lng: languagesConfig.defaultLanguageKey,
    ns: ['common', 'opinions'],
    defaultNS: 'common',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    resources: {
      pl: {
        common: commonPl,
        opinions: opinionsPl,
      },
      en: {
        common: commonEn,
        opinions: opinionsEn,
      },
    },
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('i18n initialization failed');
    // eslint-disable-next-line no-console
    console.error(error);
  });

export default i18n;
