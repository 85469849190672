import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { getCanonicalUrl } from '../utils/miscelenaus';
import { baseUrl } from '../constants';


const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;


interface SeoProps {
  pageType: PageType,
  title: string,
  lang: LanguageCode,
  description?: string,
  meta?: Array<{
    name: string,
    content: string,
  }>,
  keywords?: string[],
}

const Seo = ({
  pageType,
  title,
  description,
  lang,
  meta = [],
  keywords = [],
}: SeoProps) => {
  const { t } = useTranslation();
  const titleText = t(title);

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description ?? data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={titleText}
            titleTemplate={pageType !== 'home' ? `%s | ${data.site.siteMetadata.title as string}` : undefined}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: titleText,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: `${baseUrl}/baner-image.png`,
              },
              {
                property: 'og:image:width',
                content: '2160',
              },
              {
                property: 'og:image:height',
                content: '1082',
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: titleText,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
            ]
              .concat(keywords.length > 0
                ? {
                  name: 'keywords',
                  content: keywords.join(', '),
                }
                : [])
              .concat(pageType !== '404'
                ? {
                  property: 'og:url',
                  content: getCanonicalUrl(pageType, lang),
                }
                : [])
              .concat(meta)}
          />
        );
      }}
    />
  );
};

export default Seo;
