import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SelectLanguage from './SelectLanguage';
import LogoWombat from '../fromSvg/LogoWombat';
import BurgerMenu from './BurgerMenu';
import MenuItems from './MenuItems';
import { navBarHeight, navBarHeightSmall } from '../../constants';
import languagesConfig from '../../data/languages';


/** The transaprent value in background-color parameter here is important
    because we want the linear gradient to appear through header bar (figma project)
 */
const HeaderContainer = styled.header<{ isSticky: boolean }>`
  width: 100%;
  height: ${navBarHeightSmall};
  box-shadow: 0 4px ${({ theme }) => theme.colors.linkShadow};
  background-color: ${({ theme, isSticky }) => (isSticky ? theme.colors.blue : 'transparent')};
  position: fixed;
  z-index: 1000;
  top: 0;
  transition: box-shadow 100ms ease-in-out, background 100ms ease-in-out;
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    box-shadow: 0 ${({ isSticky }) => (isSticky ? '4px' : '0')} ${({ theme }) => theme.colors.linkShadow};
  }
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: ${navBarHeight};
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 0;
  display: flex;
  align-items: center;
  height: 80px;
  z-index: 99;
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    left: 40px;
    height: ${navBarHeight};
  }
`;

const LangContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 99;
  top: 0;
  height: ${navBarHeightSmall};
  right: 20px;
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: ${navBarHeight};
    right: 40px;
  }
`;

const MenuList = styled.ul`
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  height: ${navBarHeightSmall};
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    height: ${navBarHeight};
  }
`;

const NavWrapper = styled.div`
  display: none;
    
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    display: block;
  }
`;

const BurgerMenuWrapper = styled.div`
  display: block;
  
  @media (min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    display: none;
  } 
`;

interface NavProps {
  langsMenu: Array<{
    langKey: string,
    link: string,
    selected: boolean,
  }>,
}

const Nav = ({
  langsMenu,
}: NavProps) => {
  const whenMenuSticky: number = 40;
  const [scrolled, setScrolled] = useState(false);
  const { i18n } = useTranslation();
  const { defaultLanguageKey } = languagesConfig;
  const homeLink = i18n.language === defaultLanguageKey ? '/' : `/${i18n.language}/`;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > whenMenuSticky) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  /** This useEffect is there properly load components on scroll change */
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.addEventListener('scroll', () => {
        handleScroll();
      });
    }

    return () => { isMounted = false };
  }, []);

  return (
    <HeaderContainer
      isSticky={scrolled}
    >
      <LogoWrapper>
        <Link
          to={homeLink}
        >
          <LogoWombat />
        </Link>
      </LogoWrapper>
      <NavWrapper>
        <nav role="navigation">
          <MenuList className="fontCaviarDreams">
            <MenuItems
              isReachable
            />
          </MenuList>
        </nav>
        <LangContainer>
          <SelectLanguage langsMenu={langsMenu} />
        </LangContainer>
      </NavWrapper>
      <BurgerMenuWrapper>
        <BurgerMenu
          langsMenu={langsMenu}
        />
      </BurgerMenuWrapper>
    </HeaderContainer>
  );
};

export default Nav;
