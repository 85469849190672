import * as React from 'react';
import styled from 'styled-components';


const Rectangle = styled.rect<{ isOpened: boolean}>`
  transform: ${({ isOpened }) => (isOpened ? 'translateX(100px)' : 'translateX(0)')};
  opacity: ${({ isOpened }) => (isOpened ? '0' : '1')};
  transition: transform ease-in-out 300ms, opacity ease-in-out 300ms;
`;

const MiddleRectangle = styled.rect<{ isOpened: boolean, rotate: string}>`
  transform: ${({ isOpened, rotate }) => (isOpened ? rotate : 'rotateZ(0)')};
  transform-origin: center;
  transform-box: fill-box;
  transition: transform ease-in-out 300ms;
`;

interface SvgBurgerProps {
  isOpened: boolean,
  height: number,
  width: number,
}

const SvgBurger = ({ isOpened, height, width }: SvgBurgerProps) => (
  <svg viewBox="0 0 90 80" width={width} height={height}>
    <Rectangle isOpened={isOpened} width="90" height="18" fill="#fff" />
    <MiddleRectangle rotate="rotateZ(-45deg)" isOpened={isOpened} y="30" width="90" height="18" fill="#fff" />
    <MiddleRectangle rotate="rotateZ(45deg)" isOpened={isOpened} y="30" width="90" height="18" fill="#fff" />
    <Rectangle isOpened={isOpened} y="60" width="90" height="18" fill="#fff" />
  </svg>
);

export default SvgBurger;
