import React from 'react';
import styled from 'styled-components';
import { navbarMargin } from '../../constants';


const NavbarMargin = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  height: ${navbarMargin};
`;

interface MainProps {
  children: React.ReactNode,
  noNavbarMargin: boolean,
}

const Main = ({ children, noNavbarMargin }: MainProps) => (
  <main>
    { !noNavbarMargin && (
      <NavbarMargin />
    )}
    {children}
  </main>
);


export default Main;
