import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';


const LangMenuElement = styled.div`
  display: inline;
  width: auto;
  padding: 10px;
  color: ${props => (props.selected ? props.theme.colors.cwCoral : props.theme.colors.washedWhite)};
  font-size: ${props => (props.selected ? '1.1em' : '0.9em')};
  font-weight: ${props => (props.selected ? 'bold' : '500')};
  transition: color 200ms ease, font-size 200ms ease;
  
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;


const MenuLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1em;
`;

const Backslash = styled.span`
  color: ${({ theme }) => theme.colors.washedWhite};
`;

const SelectLanguage = ({ langsMenu }) => (
  <>
    {langsMenu.map((lang, index) => (
      <MenuLink
        to={lang.link}
        key={lang.langKey}
      >
        <LangMenuElement selected={lang.selected}>
          {lang.langKey}
        </LangMenuElement>
        {index + 1 !== langsMenu.length && <Backslash>\</Backslash>}
      </MenuLink>
    ))}
  </>
);

SelectLanguage.propTypes = {
  langsMenu: PropTypes.arrayOf(PropTypes.shape({
    langKey: PropTypes.string,
    link: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
};

export default SelectLanguage;
