import React, { useEffect } from 'react';
import { i18n as i18nType } from 'i18next';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { WindowLocation } from '@reach/router';
import '../../utils/i18n';
import Nav from './Nav';
import Footer from './Footer';
import Main from './Main';
import LanguageContext from '../../contexts/LanguageContext';
import languagesConfig from '../../data/languages';
import { GlobalStyle } from '../../theme/globalStyle';


const getLanguageUrl = (url: string, languageKey: LanguageCode) => {
  const { defaultLanguageKey } = languagesConfig;
  const linkPrefix = languageKey === defaultLanguageKey ? '' : `/${languageKey}/`;
  const homeLink = languageKey === defaultLanguageKey ? '/' : `/${languageKey}/`;

  const urlPaths = url.split('/');
  const mainPath = urlPaths[urlPaths.length - 1];

  if (languageKey === defaultLanguageKey) {
    return `${homeLink}${mainPath}`;
  }
  return `${linkPrefix}${mainPath}`;
};

const getUrlLanguage = (url: string) => {
  const { defaultLanguageKey, languagesList } = languagesConfig;
  const urlPaths = url.split('/');
  if (urlPaths.length < 2 || !languagesList.includes(urlPaths[1])) {
    return defaultLanguageKey;
  }
  return urlPaths[1];
};

const updateLanguage = (url: string, i18n: i18nType) => {
  const urlLanguage = getUrlLanguage(url);
  if (urlLanguage !== i18n.language) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(urlLanguage);
  }
};

interface LayoutProps {
  children: React.ReactNode,
  location: WindowLocation,
  noNavbarMargin?: boolean,
}


const Layout = ({
  children,
  location,
  noNavbarMargin = false,
}: LayoutProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { languagesList } = languagesConfig as LanguagesConfig;
  const languagesMenu = languagesList.map((languageKey) => ({
    link: getLanguageUrl(location.pathname, languageKey),
    langKey: languageKey,
    selected: currentLanguage === languageKey,
  }));

  useEffect(() => {
    updateLanguage(location.pathname, i18n);
  }, [i18n, location]);

  return (
    <>
      <GlobalStyle />
      <LanguageContext.Provider value={i18n.language}>
        <Nav
          langsMenu={languagesMenu}
        />
        <Main noNavbarMargin={noNavbarMargin}>
          {children}
        </Main>
        <Footer />
      </LanguageContext.Provider>
    </>
  );
};


export default Layout;
