import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Caviar Dreams";
    src: url("/fonts/CaviarDreams.ttf") format("truetype");
  }

  @font-face {
    font-family: "Caviar Dreams";
    src: url("/fonts/CaviarDreams_Bold.ttf") format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "Caviar Dreams";
    src: url("/fonts/CaviarDreams_BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: "Caviar Dreams";
    src: url("/fonts/CaviarDreams_Italic.ttf.ttf") format("truetype");
    font-style: italic;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html, body {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.white}
  }
  
  ::selection {
    background-color: ${({ theme }) => theme.colors.cwCoralSelection};
    color: ${({ theme }) => theme.colors.white};
  }
  
  /** this style is here to take outline of slide in nuka-carousel component*/
  .slider-slide {
    :focus {
      outline: none;
    }
  }
  
  a#CybotCookiebotDialogBodyLevelButtonAccept,
  a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: ${({ theme }) => theme.colors.cwCoral} !important;
    border-color: ${({ theme }) => theme.colors.cwCoral} !important;
  }
`;
