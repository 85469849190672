if (!process.env.GATSBY_GOOGLE_API_KEY) {
  throw new Error('GATSBY_GOOGLE_API_KEY is null, checkout .env file or CI env.');
}

if (!process.env.GATSBY_VERCEL_URL) {
  throw new Error('GATSBY_VERCEL_URL is null, checkout .env file or CI env.');
}

export const baseUrl = process.env.GATSBY_VERCEL_URL;
export const googleMapApiKey = process.env.GATSBY_GOOGLE_API_KEY;


export const navBarHeight = '100px';
export const navBarHeightSmall = '80px';
export const navbarMargin = '130px';
export const footerHeight = '100px';

export const projectList: PortfolioProject[] = [
  {
    name: 'projectFreshers',
    externalLink: 'https://www.behance.net/gallery/97316979/Freshers-Ilustrowany-landing-page',
    size: 'normal',
  },
  {
    name: 'projectJurasz',
    externalLink: 'https://www.behance.net/gallery/95691233/Juraszeu-Strona-firmowa',
    size: 'normal',
  },
  {
    name: 'projectLogofolio',
    externalLink: 'https://www.behance.net/gallery/98232003/Logofolio-Common-Wombat',
    size: 'normal',
  },
  {
    name: 'projectKartyAkcjiZyciowych',
    externalLink: 'https://www.behance.net/gallery/95483587/Karty-Akcji-Zyciowych-Produkt-i-prezencja-w-sieci',
    size: 'normal',
  },
  {
    name: 'projectFotografia',
    externalLink: 'https://www.behance.net/gallery/97312325/Nina-Skwira-Portfolio-fotograficzne-i-blog',
    size: 'normal',
  },
  {
    name: 'projectLogofolio2',
    externalLink: 'https://www.behance.net/gallery/98978599/Logofolio-czesc-2',
    size: 'big',
  },
  {
    name: 'projectSouthBalticBridge',
    externalLink: 'https://www.behance.net/gallery/95350191/South-Baltic-Bridge-Strona-internetowa-i-branding',
    size: 'normal',
  },
  {
    name: 'projectPrzychodniaMorena',
    externalLink: 'https://www.behance.net/gallery/97315847/NZOZ-Morena-Kampania-marketingowa',
    size: 'normal',
  },
  {
    name: 'projectStarter',
    externalLink: 'https://www.behance.net/gallery/95356151/Starter-Pack-Strona-produktu',
    size: 'normal',
  },
  {
    name: 'projectPrzychodniaMorenaMurale',
    externalLink: 'https://www.behance.net/gallery/102395663/Murale-dla-NZOZ-Morena',
    size: 'big',
  },
  {
    name: 'projectTransportApp',
    externalLink: 'https://www.behance.net/gallery/95405855/TransportApp-Aplikacja-dla-kierowcow',
    size: 'normal',
  },
  {
    name: 'projectOpenSkiff',
    externalLink: 'https://www.behance.net/gallery/97374767/My-Live-Regatta-Dedykowana-aplikacja-eventowa',
    size: 'normal',
  },
  {
    name: 'projectPowrotDoKin',
    externalLink: 'https://www.behance.net/gallery/101809117/Powrot-do-kin-studyjnych',
    size: 'normal',
  },
];

/** Thanks to reverse function, ordering logos should be more
 intuitive now - before the first item in array was actually the last item due
 to positioning elements in divs from left to right */
export const clientsLogos = [
  {
    fileName: 'kinaStudyjne',
    externalLink: 'http://www.kinastudyjne.pl/',
  },
  {
    fileName: 'primavera',
    externalLink: 'https://primaveraspa.pl/',
  },
  {
    fileName: 'enelion',
    externalLink: '',
  },
  {
    fileName: 'myLiveRegatta',
    externalLink: 'https://new.myliveregatta.com/',
  },
  {
    fileName: 'starter',
    externalLink: 'https://www.inkubatorstarter.pl/',
  },
  {
    fileName: 'openSkiff',
    externalLink: 'https://www.openskiff.org/',
  },
  {
    fileName: 'akcjaMenstruacja',
    externalLink: 'https://www.facebook.com/akcjamenstruacja/',
  },
  {
    fileName: 'talkersi',
    externalLink: 'https://talkersi.pl/',
  },
  {
    fileName: 'przychodniaMorena',
    externalLink: 'https://www.nzozmorena.pl/',
  },
  {
    fileName: 'clickAndGo',
    externalLink: 'https://www.clickandgo.com/',
  },
  {
    fileName: 'swps',
    externalLink: 'https://www.swps.pl/',
  },
  {
    fileName: 'jurasz',
    externalLink: 'https://jurasz.eu/pl/',
  },
  {
    fileName: 'opatowicz',
    externalLink: 'https://www.facebook.com/pages/category/Consulting-Agency/Opatowicz-Waker-Co-182834588454009/',
  },
].reverse();

export const foundersData = [
  {
    name: 'Krzysztof Świeczkowski',
    position: 'coFounder',
    job: 'fullStackDeveloper',
    email: 'k.swieczkowski@commonwombat.pl',
    phoneNumber: '+48 662 113 024',
  },
  {
    name: 'Stanisław Borawski',
    position: 'coFounder',
    job: 'salesAndMarketing',
    email: 's.borawski@commonwombat.pl',
    phoneNumber: '+48 512 465 238',
  },
  {
    name: 'Maciej Tkaczyk',
    position: 'coFounder',
    job: 'projectManager',
    email: 'm.tkaczyk@commonwombat.pl',
    phoneNumber: '+48 692 719 399',
  },
  {
    name: 'Mikołaj Marczak',
    position: 'coFounder',
    job: 'mobileAndWebDeveloper',
    email: 'm.marczak@commonwombat.pl',
    phoneNumber: '+48 605 142 102',
  },
];

export const companyData = {
  name: 'Common Wombat',
  addres: 'Al. Grunwaldzka 203/2',
  postCode: 'Gdańsk 80-266',
  NIP: '5833099835',
  email: 'biuro@commonwombat.pl',
  phoneNumber: '+48 662 113 024',
};

export const clientsAndOpinions = [
  {
    client: 'opinions:ClientA.name',
    opinion: 'opinions:ClientA.opinion',
  },
  {
    client: 'opinions:ClientB.name',
    opinion: 'opinions:ClientB.opinion',
  },
  {
    client: 'opinions:ClientC.name',
    opinion: 'opinions:ClientC.opinion',
  },
  {
    client: 'opinions:ClientD.name',
    opinion: 'opinions:ClientD.opinion',
  },
  {
    client: 'opinions:ClientE.name',
    opinion: 'opinions:ClientE.opinion',
  },
];
